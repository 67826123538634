import ToastComponent from './Toast.vue' /* 引入的时候，Toast.vue中的script代码就执行了 */
const Toast = {}

Toast.install = function (Vue) {

	let ToastComponentConstructor = Vue.extend(ToastComponent)
	let toastInstance = new ToastComponentConstructor() /* 此时，Toast组件的created方法会执行, 创建一个toast组件实例 */
	toastInstance.$mount()
	let toastInstanceEl = toastInstance.$mount().$el
	document.body.appendChild(toastInstance.$mount().$el) /* 获取组件挂在后的dom，添加到父节点 */
	document.body.appendChild(toastInstance.$mount().$el) /* 同一个dom，默认就只能添加到dom树的一个位置，如果添加多次到同一个父节点无效，如果是添加到另外一个父节点，则前面的会取消最后面的生效 */

	Vue.prototype.$toast = function (type, msg) {
		toastInstance.showMsg(type, msg) /* 通过操作组件身上的方法，来操作该组件挂载的dom */
	}


	/* 结合上面的测试，可以发现，
	   1. 我们写的vue文件，其实就是在定义一个对象，这个对象，指定模板和配置选项，
	   2. Vue根据我们定义的这个对象，使用Vue.extend({..})方法创建vue组件构造函数，
	   3. 使用new调用这个构造函数，就会创建出一个vue组件实例，同时这个组件实例的created方法会被调用（这个created方法就是我们定义vue文件时，自己写的） 
	   4. 同一个组件实例调用$mount方法后，只会生成一个$el在组件身上，后面组件实例继续调用$mount, 获取的就是该组件实例上的$el。并且都会执行mounted方法。
	   5. 把组件挂在后，获取的$el添加到真实dom节点
	   5. 通过调用组件身上的方法，然后就可以操作dom了
	*/
}

export default Toast
