import axios from 'axios'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
	// baseURL: "http://192.168.10.77:100/dev-api/",
	baseURL: "/api",
	// baseURL: "/dev-api",
	timeout: 20000
})

// request拦截器
service.interceptors.request.use(config => {
	config.headers['clientId'] = 5;
	return config
}, error => {
	console.log(error)
	Promise.reject(error)
})

// // 响应拦截器
// service.interceptors.response.use(res => {
// 	return res.data
// }
// )
// 申请试用
export function apply(data) {
	return service({
		url: '/system/trial/application/v1/create',
		method: 'post',
		data: data
	})
}
export default service
