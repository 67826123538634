<template>
    <div class="home">
        <Nav :isScroll="true"></Nav>
        <!-- 
        <el-carousel :interval="1000" style="height: 500px;" class="banner_carousel">
            <el-carousel-item style="height: 100%;">
                <div class="banner">
                    <div class="banner_content">
                        <div class="banner-title">
                            <img src="../assets/banner-icon.png" width="64px" height="64px" />
                            萤火虫 • 旅游SaaS服务
                        </div>
                        <div class="line"></div>
                        <div class="banner-text">1分钟开店，最小的个体也能发光</div>
                        <div class="banner-text">
                            针对旅行社、领队、导游、社区团长、网红达人的旅游营销平台
                        </div>
                        <div class="banner-button hidden-sm-and-down" @click="handleVisible">
                            立即试用体验>>
                        </div>
                        <div class="banner-button hidden-md-and-up" @click="$router.push('/apply')">
                            立即试用体验>>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item style="height: 100%;">
                <div class="banner2">
                    <div class="banner_content">
                        <div class="banner-title">
                            <img src="../assets/banner-icon.png" width="64px" height="64px" />
                            萤火虫 • 旅游SaaS服务
                        </div>
                        <div class="line"></div>
                        <div class="banner-text">1分钟开店，最小的个体也能发光</div>
                        <div class="banner-text">
                            针对旅行社、领队、导游、社区团长、网红达人的旅游营销平台
                        </div>
                        <div class="banner-button hidden-sm-and-down" @click="handleVisible">
                            立即试用体验>>
                        </div>
                        <div class="banner-button hidden-md-and-up" @click="$router.push('/apply')">
                            立即试用体验>>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel> -->
        <swiper ref="mySwiper" class="banner_carousel" style="height: 500px;" :options="options">
            <swiper-slide>
                <div class="banner">
                    <div class="banner_content">
                        <div class="banner-title">
                            <img src="../assets/banner-icon.png" width="64px" height="64px" />
                            萤火虫 • 旅游SaaS服务
                        </div>
                        <div class="line"></div>
                        <div class="banner-text">1分钟开店，最小的个体也能发光</div>
                        <div class="banner-text">
                            针对旅行社、领队、导游、社区团长、网红达人的旅游营销平台
                        </div>
                        <div class="banner-button hidden-sm-and-down" @click="handleVisible">
                            立即试用体验>>
                        </div>
                        <div class="banner-button hidden-md-and-up" @click="$router.push('/apply')">
                            立即试用体验>>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="banner2">
                    <div class="banner_content">
                        <div class="banner-title">
                            <img src="../assets/banner-icon.png" width="64px" height="64px" />
                            萤火虫 • 旅游SaaS服务
                        </div>
                        <div class="line"></div>
                        <div class="banner-text">1分钟开店，最小的个体也能发光</div>
                        <div class="banner-text">
                            针对旅行社、领队、导游、社区团长、网红达人的旅游营销平台
                        </div>
                        <div class="banner-button hidden-sm-and-down" @click="handleVisible">
                            立即试用体验>>
                        </div>
                        <div class="banner-button hidden-md-and-up" @click="$router.push('/apply')">
                            立即试用体验>>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <!-- 优质资源库 -->
        <div class="resources">
            <div>
                <div class="resources-title">优质资源库</div>
                <div class="resources-text">旅游业务全覆盖</div>
            </div>
            <div class="resources_grid">
                <div class="resources-item">
                    <img width="64px" height="64px" src="../assets/plan.png" alt="" />
                    <div class="resources-item-title">机票</div>
                    <div class="resources-item-text">
                        无缝对接中航信GDS系统，实时出票退改签
                    </div>
                </div>
                <div class="resources-item">
                    <img width="64px" height="64px" src="../assets/train.png" alt="" />
                    <div class="resources-item-title">火车票</div>
                    <div class="resources-item-text">
                        集成12306火车票预订，一站式解决大交通
                    </div>
                </div>
                <div class="resources-item">
                    <img width="64px" height="64px" src="../assets/tourism.png" alt="" />
                    <div class="resources-item-title">旅游度假</div>
                    <div class="resources-item-text">
                        品质线路、集中采购，一键上架分销
                    </div>
                </div>
                <div class="resources-item">
                    <img width="64px" height="64px" src="../assets/hotel.png" alt="" />
                    <div class="resources-item-title">酒店</div>
                    <div class="resources-item-text">直连酒店、对接多方资源库</div>
                </div>
                <div class="resources-item">
                    <img width="64px" height="64px" src="../assets/ticket.png" alt="" />
                    <div class="resources-item-title">景点门票</div>
                    <div class="resources-item-text">优质景点，最惠价格，一网打尽</div>
                </div>
                <div class="resources-item">
                    <img width="64px" height="64px" src="../assets/visa.png" alt="" />
                    <div class="resources-item-title">签证</div>
                    <div class="resources-item-text">
                        自营分销任选，资深签证专家为您服务
                    </div>
                </div>
            </div>
        </div>
        <div>

        </div>
        <!-- 简单灵活 -->
        <div class="resources">
            <div>
                <div class="resources-title">简单灵活</div>
                <div class="resources-text">3分钟快速上手，一键上架产品</div>
            </div>
            <div class="simple">
                <div class="simple_img hidden-xs-only">
                    <img width="615px" height="552px" src="../assets/simple.png" alt="" />
                </div>
                <div class="simple-item_flex">
                    <div class="simple-item">
                        <div class="simple-item-number">1</div>
                        <div>
                            <div class="simple-item-title">所见所得</div>
                            <div class="resources-item-text">
                                实时录入，实时预览，学习成本低。
                            </div>
                        </div>
                    </div>
                    <div class="simple-item">
                        <div class="simple-item-number">2</div>
                        <div>
                            <div class="simple-item-title">灵活度高</div>
                            <div class="resources-item-text">
                                多种界面模板、首页风格，可按组件模块定制自己的专属界面
                            </div>
                        </div>
                    </div>
                    <div class="simple-item">
                        <div class="simple-item-number">3</div>
                        <div>
                            <div class="simple-item-title">上手指南</div>
                            <div class="resources-item-text">
                                提供文本、视频多媒体操作手册，外行也能从容管理后台。
                            </div>
                        </div>
                    </div>
                    <div class="simple-item">
                        <div class="simple-item-number">4</div>
                        <div>
                            <div class="simple-item-title">技术支持</div>
                            <div class="resources-item-text">
                                7×24小时，在线技术支持，解决你的后顾之忧。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 4步开通专属旅游营销小程序 -->
        <div class="bg1">
            <div class="resources">
                <div>
                    <div class="resources-title">4步开通专属旅游营销小程序</div>
                    <div class="resources-text">方便快捷，简单易用</div>
                </div>
                <ul class="steps">
                    <li>免费申请开通</li>
                    <li>店铺DIY装修</li>
                    <li>收款账号配置</li>
                    <li>产品上架运营</li>
                </ul>
            </div>
        </div>
        <div class="resources">
            <div>
                <div class="resources-title">合作伙伴</div>
                <div class="resources-text">他们都在用萤火虫</div>
            </div>
            <div class="partner">
                <div class="partner-item">
                    <img src="../assets/yiyou.png" width="216px" height="100px" />
                </div>
                <div class="partner-item">
                    <img src="../assets/shangqinglv.png" width="216px" height="100px" />
                </div>
                <div class="partner-item">
                    <img src="../assets/haixing.png" width="216px" height="100px" />
                </div>
                <div class="partner-item">
                    <img src="../assets/damei.png" width="216px" height="100px" />
                </div>
                <div class="partner-item">
                    <img src="../assets/kaixin.png" width="216px" height="100px" />
                </div>
            </div>
        </div>
        <Footer></Footer>
        <apply :visible="visible" @close="(e) => (visible = e)"></apply>
    </div>
</template>

<script>

import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import apply from "@/components/apply.vue";
export default {
    name: "HomeView",
    components: {
        Nav,
        Footer,
        apply,
    },

    data() {
        return {
            options: {
                loop: true,
                autoplay: {
                    // 自动播放时间：毫秒
                    delay: 5000,
                    disableOnInteraction: false,
                },
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    // bulletClass: "my-bullet",
                },
            },
            banner1: require('../assets/banner1.png'),
            banner2: require('../assets/banner2.png'),
            m_banner1: require('../assets/homeBanner_m.png'),
            m_banner2: require('../assets/homeBanner2_m.png'),
            visible: false,
            someList: [
                {
                    title: "独立品牌",
                    text: "自定义品牌名称、LOGO，你的品牌你做主。",
                },
                {
                    title: "会员营销",
                    text: "优惠券、限时折扣、满减送、多人拼团、多种营销工具任选",
                },
                {
                    title: "留存客户",
                    text: "渠道客户集中留存，把粉丝变成 终身客户",
                },
            ],
        };
    },
    methods: {
        handleVisible() {
            // console.log("first");
            this.visible = true;
        },
    },
};
</script>

<style lang="scss">
.home {
    .el-carousel__container {
        height: 100% !important;
    }
}
</style>
<style scoped lang="scss">
.home {
    background: #fff;



    @media screen and (min-width:768px) {


        .banner_carousel {

            .banner,
            .banner2 {
                height: 100%;
                background: url('../assets/banner1.png') no-repeat;
                background-size: 100% 100%;
                display: flex;
                padding-left: 10vw;
                flex-direction: column;
                justify-content: center;

                .banner-title {
                    font-size: 42px;
                    font-family: Microsoft YaHei-Semibold, Microsoft YaHei;
                    font-weight: 600;
                    color: #ffffff;
                    line-height: 56px;
                    display: flex;
                }

                .line {
                    width: 140px;
                    height: 4px;
                    background-color: #fff;
                    margin: 28px 0;
                }

                .banner-text {
                    font-size: 20px;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.9);
                    line-height: 38px;
                }

                .banner-button {
                    margin-top: 40px;
                    width: 192px;
                    line-height: 40px;
                    border-radius: 20px;
                    cursor: pointer;
                    text-align: center;
                    background-color: #fff;
                    font-size: 20px;
                    font-weight: bold;
                    color: #0071d9;
                    user-select: none;
                }

            }

            .banner2 {
                background: url('../assets/banner2.png') no-repeat;
                background-size: 100% 100%;
            }
        }

    }


    @media screen and (max-width:768px) {
        background: #E7EBF4;

        .banner_carousel {
            height: 375px !important;

            .el-carousel__container {
                height: 100%;
            }

            .banner,
            .banner2 {
                height: 100%;
                background: url('../assets/homeBanner_m.png') no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 24px;

                &_content {
                    margin-top: 40px;
                    padding-top: 40px;
                    background: rgba(20, 40, 64, 0.50);
                    text-align: center;
                }

                .banner-title {
                    font-size: 24px;
                    font-family: Microsoft YaHei-Semibold, Microsoft YaHei;
                    font-weight: 600;
                    color: #ffffff;
                    line-height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    img {
                        width: 25px;
                        height: 25px;
                        margin-right: 5px;
                    }
                }

                .line {
                    width: 40px;
                    height: 4px;
                    background-color: #fff;
                    margin: 12px auto;
                }

                .banner-text {
                    font-size: 12px;
                    line-height: 22px;
                    font-weight: 400;
                    padding: 0 50px;
                    color: rgba(255, 255, 255, 0.9);
                }

                .banner-button {
                    width: 111px;
                    height: 34px;
                    line-height: 34px;
                    background: #FFFFFF;
                    border-radius: 3px 3px 3px 3px;
                    font-size: 12px;
                    font-weight: bold;
                    color: #0071d9;
                    user-select: none;
                    margin: 24px auto;
                }

            }

            .banner2 {
                background: url('../assets/homeBanner2_m.png') no-repeat;
                background-size: 100% 100%;
            }
        }

        .simple {
            padding: 24px 32px;
            margin-bottom: 12px;

            .simple-item_flex {
                display: flex;
                flex-direction: column;
                gap: 12px;

                .simple-item {
                    width: calc(100% - 32px);
                    height: 83px;
                    background: #F8F9FB;
                    border-radius: 0px 0px 0px 0px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding-left: 32px;
                    text-align: left;
                    padding-right: 16px;

                    &-title {
                        font-size: 15px;
                    }

                    &-number {
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        background: #00ABE6;
                        border-radius: 22px 22px 22px 22px;
                        justify-content: center;
                        position: absolute;
                        left: -16px;
                        font-size: 20px;
                        color: #fff;
                        font-weight: 700;
                        text-align: center;
                    }
                }

            }
        }

        .bg1 {
            width: 100%;

            .resources {
                aspect-ratio: 1 / 1;
                background: url('../assets/miniProgramBgc.png');
                background-size: cover;
                display: flex;
                flex-direction: column;
                justify-content: center;

                >div {
                    margin-bottom: 24px;

                }

                .resources-title {
                    font-size: 16px;
                    font-weight: 400;
                    color: #030201;
                }

                /* 进度条 */
                .steps {
                    width: 100%;
                    counter-reset: step;
                    height: 100px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .steps li {
                    list-style-type: none;
                    font-size: 12px;
                    font-weight: 400;
                    color: #333333;
                    font-size: 12px;
                    font-weight: 400;
                    text-align: center;
                    width: 25%;
                    position: relative;
                    color: #333;
                    z-index: 2;
                    float: left;

                    &::after {
                        content: "";
                        width: 100%;
                        height: 1px;
                        background-color: #b3b3b3;
                        position: absolute;
                        left: 0;
                        top: 22px;
                        z-index: -1;
                    }

                    &::before {
                        display: block;
                        content: counter(step);
                        counter-increment: step;
                        width: 44px;
                        height: 44px;
                        background-color: #fff;
                        line-height: 44px;
                        border-radius: 50%;
                        font-size: 20px;
                        color: #666666;
                        text-align: center;
                        font-weight: 500;
                        margin: 0 auto 8px auto;
                        border: 1px #999999 solid;
                    }
                }

                .steps li:first-child:after {
                    width: 50%;
                    left: 50%;
                }

                .steps li:last-child:after {
                    width: 50%;
                }
            }
        }

        .partner {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
            padding: 10px;

            .partner-item {
                display: flex;
                aspect-ratio: 100 / 46;

            }

            img {
                height: auto !important;
                width: 100% !important;
            }
        }
    }


    @media screen and (min-width:768px) {
        .resources {
            // width: 1200px;
            width: 80%;
            margin: 0 auto;
            padding: 68px 0;
            text-align: center;
        }

        .resources_grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
            align-items: center;
            gap: 24px;
        }

        .resources-title {
            font-size: 36px;
            font-weight: 400;
            color: #030201;
        }

        .resources-text {
            margin-top: 24px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            margin-bottom: 24px;
        }

        .resources-item {
            box-sizing: border-box;
            padding: 53px 0;
            width: 100%;
            height: 260px;
            background: #f8f8f8;
        }

        .resources-item-title {
            margin: 24px 0 10px 0;
            font-size: 20px;
            font-weight: 400;
            color: #120b08;
        }

        .resources-item-text {
            margin-top: 4px;
            font-size: 14px;
            color: #666666;
        }

        .simple {
            margin-top: 40px;
            display: flex;
            justify-content: space-around;

            >div {
                flex: 1;
            }

            img {
                // aspect-ratio: auto 5 / 3； // width: 50%;
                width: 100%;
                // height: 276px;
            }
        }

        .simple-item {
            flex: 1;
            height: 123px;
            overflow: hidden;
            box-sizing: border-box;
            background: #f8f8f8;
            display: flex;
            align-items: center;
            padding: 32px;
            text-align: left;
            margin-bottom: 20px;
        }

        .simple-item:hover,
        .swipper-item:hover {
            background-color: #0b52d1;
        }

        .simple-item:hover *,
        .swipper-item:hover * {
            color: #ffffff;
        }

        .simple-item-number {
            margin-right: 24px;
            font-size: 32px;
            font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
            font-weight: 400;
            color: #00abe6;
        }

        .simple-item-title {
            font-size: 20px;
            font-weight: 400;
            color: #120b08;
            line-height: 30px;
        }

        /* 进度条 */
        .steps {
            position: relative;
            counter-reset: step;
            height: 260px;
            margin: 0 auto;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .steps li {
            list-style-type: none;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            width: 20%;
            position: relative;
            color: #333333;
            z-index: 2;
            float: left;
        }

        .steps li:before {
            display: block;
            content: counter(step);
            counter-increment: step;
            width: 110px;
            height: 110px;
            background-color: #fff;
            line-height: 110px;
            border-radius: 50%;
            font-size: 40px;
            color: #666666;
            text-align: center;
            font-weight: 500;
            margin: 0 auto 8px auto;
            border: 1px #999999 solid;
        }

        .steps li:after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #b3b3b3;
            position: absolute;
            left: 0;
            top: 55px;
            z-index: -1;
        }

        .steps li:first-child {
            z-index: 3;
        }

        .steps li:last-child {
            z-index: 1;
        }

        .steps li:first-child:after {
            width: 50%;
            left: 50%;
        }

        .steps li:last-child:after {
            width: 50%;
        }

        .bg1 {
            background: url("../assets/bg1.png") no-repeat;
            background-size: cover;
        }

        .partner {
            width: 100%;
            height: 200px;
            /* background-color: #000; */
            padding: 58px 0;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
        }

        .partner-item {
            height: 100px;
            border-radius: 0px 0px 0px 0px;
            border: 1px solid #e8e8e8;
        }

    }

    @media screen and (min-width: 1200px) {
        .resources {
            width: 1200px;
            padding: 68px 0 24px;

        }

        .resources_grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;
            align-items: center;
        }

        .simple-item {
            width: 545px;
        }
    }

    @media screen and (max-width:768px) {

        .resources {
            width: 95%;
            background: #fff;
            margin: 0 auto;
            text-align: center;
            padding-top: 30px;
            margin-bottom: 12px;

            .resources-title {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
            }

            .resources-text {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                margin-top: 10px;
            }

            .resources_grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding: 16px;
                gap: 12px;
                justify-items: center;
                align-items: center;
            }

            .resources-item {
                box-sizing: border-box;
                width: 100%;
                height: 150px;
                background: #f8f8f8;
                padding: 24px 12px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-around;

                img {
                    width: 32px;
                    height: 32px;
                }
            }

            .resources-item-title {
                // margin: 24px 0 10px 0;
                font-size: 14px;
                font-weight: 400;
                color: #120B08;
                line-height: 15px;
            }

            .resources-item-text {
                font-size: 12px;
                color: #666666;
            }
        }
    }

    @media screen and (max-width: 1200px) and (min-width: 768px) {
        .partner {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;

            gap: 10px;
            padding: 10px;

            .partner-item {
                display: flex;
                aspect-ratio: 100 / 46;

            }

            img {
                height: auto !important;
                width: 100% !important;
            }
        }
    }

}

.resources {}
</style>
