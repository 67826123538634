<template>
  <div class="apply" v-if="visible">
    <div class="info">
      <div class="close" @click="close">X</div>
      <div class="title">申请试用</div>
      <div class="info-item">
        <div class="info-item-lable">公司名称</div>
        <div>
          <input class="info-item-input" v-model="corpName" type="text" placeholder="请输入公司名称" />
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-lable">性别</div>
        <div class="info-item-groud">
          <input class="info-item-radio" type="radio" v-model="gender" name="gender" value="男士" />男
          <input class="info-item-radio" type="radio" v-model="gender" name="gender" value="女士" />女
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-lable">称呼</div>
        <div>
          <input class="info-item-input" type="text" v-model="nickname" placeholder="请输入您的姓名或称呼" />
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-lable">手机</div>
        <div>
          <input class="info-item-input" type="text" v-model="phone" placeholder="请输入您的手机号" />
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-lable">职位</div>
        <div>
          <input class="info-item-input" v-model="position" type="text" placeholder="请输入您的职位" />
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-lable">申请试用主体</div>
        <div class="info-item-groud">
          <input class="info-item-radio" type="radio" name="bulk" v-model="bulk" value="旅行社" />旅行社
          <input class="info-item-radio" type="radio" name="bulk" v-model="bulk" value="企业工会" />企业工会
          <input class="info-item-radio" type="radio" name="bulk" v-model="bulk" value="领队" />领队
          <input class="info-item-radio" type="radio" name="bulk" v-model="bulk" value="导游" />导游
          <input class="info-item-radio" type="radio" name="bulk" v-model="bulk" value="社区团长" />社区团长
          <input class="info-item-radio" type="radio" name="bulk" v-model="bulk" value="网红达人" />网红达人
        </div>
      </div>
      <div class="info-button" @click="submit">提交申请</div>
    </div>
  </div>
</template>
<script>
import { apply } from "@/api/request.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      corpName: "",
      gender: "",
      nickname: "",
      phone: "",
      position: "",
      bulk: "",
    };
  },
  methods: {
    close() {
      this.corpName = "";
      this.gender = "";
      this.nickname = "";
      this.phone = "";
      this.position = "";
      this.bulk = "";
      this.$emit("close", false);
    },
    submit() {
      if (
        this.corpName &&
        this.gender &&
        this.nickname &&
        this.phone &&
        this.position &&
        this.bulk
      ) {
        if (
          !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            this.phone
          )
        ) {
          this.$toast("error", "请输入正确的手机号");
          return;
        }
        apply({
          companyName: this.corpName,
          salutation: this.nickname,
          gender: this.gender,
          phone: this.phone,
          applicationSubject: this.bulk,
          position: this.position,
        }).then((res) => {
          // console.log(res);
          if (res.data.code == 200) {
            this.$toast("success", "提交成功");
            this.close();
          } else {
            this.$toast("error", "提交失败");
          }
        });
      } else {
        this.$toast("error", "请先完成信息填写");
      }
    },
  },
};
</script>
<style scoped>
.info-item-groud {
  color: #999;
}

input::-webkit-input-placeholder {
  /* placeholder颜色 */
  color: #999;
  /* placeholder字体大小 */
  /* font-size:12px */
}

.info-button {
  width: 380px;
  height: 48px;
  line-height: 48px;
  color: #ffffff;
  text-align: center;
  background: #0b52d1;
  border-radius: 4px;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  user-select: none;
}

.info-item {
  display: flex;
  padding: 15px 0;
  align-items: center;
  color: #000000;
}

.info-item-lable {
  width: 120px;
  padding: 0 20px;
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.info-item-input {
  height: 40px;
  width: 836px;
  box-sizing: border-box;
  padding: 0 15px;
  line-height: 40px;
  border-radius: 4px;
  border: none;
  outline: none;
  border: 1px solid #eee;
}

/deep/.info-item-radio {
  margin: 0 10px;
}

/deep/.info-item-radio:checked {
  background-color: #0b52d1;
  background-clip: content-box;
}

/* input[type="radio"] {
  margin: 0 10px;
}
input[type="radio"]:checked {
  outline: none !important;
  border: 1px solid #93b5ec !important;
} */
.apply {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.info {
  width: 1000px;
  height: 600px;
  padding: 48px 100px;
  background-color: rgba(255, 255, 255);
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
  line-height: 38px;
  text-align: center;
  margin-bottom: 51px;
}

.close {
  position: absolute;
  right: 40px;
  top: 20px;
  color: #000000;
  cursor: pointer;
}
</style>