import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Toast from '@/components/Toast.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Toast)
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
