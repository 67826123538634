<template>
  <div class="footer">
    <div class="hidden-sm-and-down">
      <div class="footer-content">
        <div style="flex: 1">
          <div>
            <img width="220px" height="50px" src="../assets/logo2.png" />
          </div>
          <div class="line"></div>
          <div class="footer-text">上海英胜旅游信息科技有限公司</div>
          <div class="footer-text">咨询热线：400-820-3330</div>
          <div class="footer-text">公司地址：上海市黄浦区龙华东路917号708室</div>
        </div>
        <div style="flex: 1">
          <div class="footer-text">技术支持</div>
          <div class="line"></div>
          <div class="footer-text click" @click="handleClick(0)">
            如何配置服务费
          </div>
          <div class="footer-text click" @click="handleClick(1)">
            如何上传产品
          </div>
          <div class="footer-text click" @click="handleClick(2)">
            产品上架流程
          </div>
          <div class="footer-text click" @click="handleClick(3)">
            如何配置首页
          </div>
          <div class="footer-text click" @click="handleClick(4)">
            业务流程说明
          </div>
        </div>
        <div class="QRcode">
          <div>
            <img src="../assets/QRcode.jpg" alt="" srcset="">
            <div>请扫二维码</div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div>
          ©版权所有
          <span @click="jump" style="cursor: pointer">沪ICP备2023018850号-1</span>
        </div>
      </div>
    </div>
    <div class="hidden-md-and-up footer_content">
      <img src="../assets/logo2.png" />
      <div class="companyMsg">
        <div class="line"></div>
        <div class="footer-text">上海英胜旅游信息科技有限公司</div>
        <div class="footer-text">咨询热线：400-820-3330</div>
        <div class="footer-text">公司地址：上海市黄浦区龙华东路917号708室</div>
      </div>
      <div class="QRcode">
        <img src="../assets/QRcode.jpg" width="60px" height="60px" alt="" srcset="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleClick(index) {
      this.$emit("ChangeType", index);
      this.$router.push("/about?type=" + index);
    },
    jump() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (min-width:768px) {
  .footer {
    width: 100%;
    height: 400px;
    padding: 50px 200px;
    box-sizing: border-box;
    background-color: #000b3e;
  }

  .footer-content {
    height: 300px;
    width: 1200px;
    display: flex;
    margin: 0 auto;
    position: relative;
    .QRcode{
      // height: 100%;
      img{
        width: 130px;
        height: 130px;
      }
      // display: grid;
      // align-items: center;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      color: #ffffff;
      text-align: center;
      padding-left: 100px;
      border-left: 1px solid #ffffff;
    }
  }

  .line {
    width: 40px;
    height: 2px;
    background-color: #d9d9d9;
    margin: 15px 0 24px 65px;
  }

  .footer-text {
    margin-left: 65px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
  }

  .click {
    cursor: pointer;
  }

  .footer-bottom {
    /* border-top: 1px solid #d9d9d9; */
    /* width: 1200px; */
    margin: 0 auto;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
  }
}

@media screen and (max-width:992px) {
  .footer {
    width: 100%;
    height: 300px;
    background-color: #000b3e;

    .footer_content {
      padding: 24px 16px;
      position: relative;
      .QRcode{
        img{
          width: 60px;
          height: 60px;
        }
        position: absolute;
        right: 30px;
        top: 20px;
      }
      img {
        width: 110px;
        height: 25px;
      }

      .companyMsg {
        color: #ffffff;
        padding-left: 50px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        .line {
          width: 40px;
          height: 2px;
          margin: 12px 0;
          background-color: #d9d9d9;
        }
      }
    }
  }
}

@media screen and (max-width:768px) {
  .footer {
    width: 100%;
    height: 168px;
    background-color: #000b3e;

    .footer_content {
      padding: 24px 16px;
      position: relative;
      .QRcode{
        img{
          width: 60px;
          height: 60px;
        }
        position: absolute;
        right: 30px;
        top: 20px;
      }
      img {
        width: 110px;
        height: 25px;
      }

      .companyMsg {
        color: #ffffff;
        padding-left: 50px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        .line {
          width: 40px;
          height: 2px;
          margin: 12px 0;
          background-color: #d9d9d9;
        }
      }
    }
  }
}
</style>