<template>
    <div class="nav" :style="scrollStyle">
        <div class="nav-content">
            <div @click="$router.push('/')" style="cursor: pointer">
                <img style="width: 170px;" :src="scrollPosition > 100 ? logo1 : logo2" alt="" />
                <h1 style="position: absolute; z-index: -1; opacity: 0">英盛科技</h1>
            </div>
            <div>
                <span v-if="onProbation" class="nav-button hidden-sm-and-down" @click="visible = true">申请试用</span>
                <span v-if="onProbation" class="nav-button hidden-md-and-up" @click="$router.push('/apply')">申请试用</span>
                <span class="nav-button  hidden-sm-and-down" @click="$router.push('/about')">技术支持</span>
                <span class="nav-button" @click="$router.push('/')">首页</span>
            </div>
        </div>
        <apply class="hidden-sm-and-down" :visible="visible" @close="(e) => (visible = e)"></apply>
    </div>
</template>
<script>
import apply from "@/components/apply.vue";
export default {
    name: "Nav",
    components: {
        apply,
    },
    props: {
        isScroll: {
            type: Boolean,
            default: () => {
                return false
            }
        },
        onProbation: {
            type: Boolean,
            default: () => {
                return true
            }
        },
    },
    data() {
        return {
            logo1: require('@/assets/logo1.png'),
            logo2: require('@/assets/logo2.png'),
            visible: false,
            scrollPosition: 0,
            maxScroll: 100, // 定义滚动到一定程度时背景完全变为白色

        };
    },
    mounted() {
        if (this.isScroll) {
            window.addEventListener('scroll', this.handleScroll)
        } else {
            this.scrollPosition = 120
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    computed: {
        scrollStyle() {
            const opacity = this.scrollPosition < this.maxScroll ? this.scrollPosition / this.maxScroll : 1;
            if (this.isScroll) {
                return {
                    color: opacity == 0 ? '#DADADA' : `rgba(0, 0, 0, ${opacity}`,
                    background: `rgba(255, 255, 255, ${opacity}`,
                };
            } else {
                return {
                    color: `#000`,
                    background: `rgba(255, 255, 255,1)`,
                };
            }

        },
    },
    methods: {
        handleScroll(e) {
            this.scrollPosition = window.scrollY;
        }
    },
};
</script>
<style scoped lang="scss">
.nav {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1500;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
}

.nav-content {
    /* width: 1200px; */
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.nav-button {
    cursor: pointer;
}

span+span {
    margin-left: 28px;
}

@media screen and (max-width:768px) {
    .nav {
        height: 50px;

        .nav-content {
            height: 100%;
            padding: 0 24px;
            justify-content: space-between;
            font-size: 12px;


            img {
                width: 87px !important;
            }
        }
    }
}
</style>